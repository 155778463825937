<template>
  <div class="header">
    <div class="logo">
      <router-link class="btn" to="/secure/tutun-akademi">
        <img src="~@/assets/otp/otp-logo.png" alt="otp logo" />
      </router-link>
    </div>
    <div class="header-right">
      <div class="badges">
        <div class="badge" :key="badge.id" v-for="badge in badgeData">
          <img :src="getBadgeSrc(badge)" alt="badge" />
        </div>
      </div>
      <div class="doughnut-wrapper">
        <figure>
          <div class="figure-content">
            <svg width="100%" height="100%" viewBox="0 0 42 42" class="donut" role="img">
              <linearGradient id="linearColors1" x1="0" y1="0" x2="1" y2="1">
                <stop offset="0%" stop-color="#fff3a6"></stop>
                <stop offset="50%" stop-color="#b57e10"></stop>
                <stop offset="100%" stop-color="#fff3a6"></stop>
              </linearGradient>
              <circle class="donut-hole" cx="21" cy="21" r="12" fill="transparent"></circle>
              <circle
                cx="21"
                cy="21"
                r="12"
                fill="transparent"
                stroke="#3a2527"
                stroke-width="1"
              ></circle>
              <circle
                cx="21"
                cy="21"
                r="12"
                fill="transparent"
                stroke="url(#linearColors1)"
                stroke-width="1"
                :stroke-dasharray="calcDasharray"
                stroke-dashoffset="0"
              ></circle>
              <g class="chart-text">
                <text x="50%" y="50%" class="chart-number">
                  {{ `%${percent}` }}
                </text>
              </g>
            </svg>
          </div>
        </figure>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OtpHeader',
  props: {
    percent: {
      type: Number,
      default: 0,
      required: true,
    },
    badgeData: {
      type: Array,
    },
  },
  computed: {
    calcDasharray() {
      return (this.percent * 58) / 100 + ' ' + (75 - (this.percent * 58) / 100);
    },
  },
  methods: {
    getBadgeSrc(category) {
      return require('../../../assets/otp/' +
        `${!category.isCompleted ? category.categoryId : category.categoryId + '-complated'}` +
        '.png');
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 60px;
  background-image: url('~@/assets/otp/bg/otp_header_bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-size: 16px;
  &-right {
    display: flex;
    align-items: center;
  }
}
.badges {
  display: flex;
  align-items: center;
  margin-right: 5px;
  .badge {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    margin-right: 10px;
  }
}
.doughnut-wrapper {
  width: 45px;
  height: 45px;
  position: relative;
  top: -7px;
  overflow: hidden;
}

figure {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  bottom: -7px;
}

.figure-content,
.figure-key {
  flex: 1;
  align-self: center;
  transform: rotate(135deg);
}

.figure-content svg {
  height: auto;
  transform: scale(1.6);
}
.chart-text {
  font-size: 9px;
  font-weight: 300;
  fill: #fbe78b;
  transform: rotate(225deg) translateY(3px);
  transform-origin: center;
}

.chart-number {
  text-anchor: middle;
}
</style>
